.sign-wrap {
  position: relative;
  background: url('../../assets/img/sign-bg.jpg') #fff9e2 no-repeat center / cover;
  height: 100vh;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sign-wrap .sign-btn-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3.33333333rem;
  width: 3.2rem;
  height: 3.2rem;
}
.sign-wrap .sign-btn-box .sign-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3.2rem;
  background-image: linear-gradient(#feb100, #ff9500);
  z-index: 100;
  text-align: center;
}
.sign-wrap .sign-btn-box .sign-btn .txt {
  padding-top: 1.06666667rem;
  font-size: 0.58666667rem;
  font-weight: bold;
  color: #fff;
}
.sign-wrap .sign-btn-box .sign-btn .time {
  font-size: 0.42666667rem;
  color: #fff;
  line-height: 0.8rem;
}
.sign-wrap .sign-btn-box .sign-btn-active {
  background: #ffba35;
}
.sign-wrap .sign-btn-box .sign-btn-success {
  background: #ccc;
}
.sign-wrap .sign-btn-box .miner {
  position: absolute;
  width: 2.64rem;
  top: -1.33333333rem;
  right: -1.33333333rem;
  z-index: 98;
}
.sign-wrap .btn-water {
  position: absolute;
  padding: 2.66666667rem;
  left: 50%;
  top: -1.06666667rem;
  z-index: 99;
  opacity: 0;
  border-radius: 100%;
  box-shadow: 0 0 120px 30px #ff9500 inset;
  margin-left: -2.66666667rem;
}
.sign-wrap .btn-water1 {
  animation: wateranimate 6s 0s ease-out infinite;
}
.sign-wrap .btn-water2 {
  animation: wateranimate 6s 1s ease-out infinite;
}
.sign-wrap .btn-water3 {
  animation: wateranimate 6s 2s ease-out infinite;
}
.sign-wrap .btn-water4 {
  animation: wateranimate 6s 3s ease-out infinite;
}
.sign-wrap .btn-water5 {
  animation: wateranimate 6s 4s ease-out infinite;
}
.sign-wrap .btn-water6 {
  animation: wateranimate 6s 5s ease-out infinite;
}
.sign-wrap .success-tips {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.sign-wrap .success-tips .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.sign-wrap .success-tips .tips-panel {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background: url('../../assets//img//caidai.png') no-repeat center / cover;
}
.sign-wrap .success-tips .tips-panel .tips-time {
  padding: 15% 0;
  font-size: 0.93333333rem;
  color: #fff;
}
.sign-wrap .success-tips .tips-panel .tips-body {
  padding-top: 15%;
  box-sizing: border-box;
  width: 80%;
  height: 50%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  text-align: center;
}
.sign-wrap .success-tips .tips-panel .tips-body .tips-gif {
  position: absolute;
  top: -1.46666667rem;
  left: 50%;
  transform: translateX(-50%);
  height: 2.82666667rem;
}
.sign-wrap .success-tips .tips-panel .tips-body .tips-img {
  display: block;
  height: 48%;
  margin: 0.24rem auto 0;
}
.sign-wrap .success-tips .tips-panel .tips-body .reward-desc {
  line-height: 1.04rem;
  font-size: 0.46666667rem;
  color: #ffa100;
}
.sign-wrap .success-tips .tips-panel .tips-body .slogan {
  padding: 0 0.74666667rem;
  line-height: 0.48rem;
  font-size: 0.34666667rem;
  color: #333;
}
.sign-wrap .success-tips .tips-panel .tips-fotter {
  padding-top: 0.93333333rem;
}
.sign-wrap .success-tips .tips-panel .tips-fotter .ok-btn {
  width: 2.37333333rem;
  height: 0.82666667rem;
  line-height: 0.85333333rem;
  border: 1px solid #fff;
  border-radius: 0.41333333rem;
  font-size: 0.37333333rem;
  color: #fff;
  margin: 0 auto;
}
.sign-wrap .success-tips .tips-panel .tips-fotter .ok-btn-active {
  background: rgba(255, 255, 255, 0.6);
  color: #282828;
}
.sign-wrap .signing-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.sign-wrap .signing-box .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.sign-wrap .signing-box .signing-body {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.sign-wrap .signing-box .signing-body .img {
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sign-wrap .statis-button {
  padding-left: 0.69333333rem;
  position: absolute;
  right: 0;
  top: 0.4rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  height: 0.74666667rem;
  line-height: 0.74666667rem;
  width: 2.4rem;
  border-top-left-radius: 0.37333333rem;
  border-bottom-left-radius: 0.37333333rem;
}
.sign-wrap .statis-button .prefix-icon {
  position: absolute;
  top: 0.18666667rem;
  left: 0.18666667rem;
  width: 0.37333333rem;
}
.sign-wrap .statis-button2 {
  top: 1.28rem;
}
@-webkit-keyframes wateranimate {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes wateranimate {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}
