.allsub-wrap .am-list-item .am-list-line .am-list-content {
  font-size: 0.42666667rem;
}
.allsub-wrap .goback {
  background-color: #d8d8d8;
  min-height: 34px;
}
.allsub-wrap .goback .am-list-line .am-list-content {
  padding: 0;
  text-align: center;
  font-size: 0.4rem;
}
.allsub-wrap .am-list-item.am-list-item-active {
  background-color: #ddd;
}
.allsub-wrap .user-wrap {
  display: flex;
  flex-direction: row;
}
.allsub-wrap .user-wrap .avatar-box {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  overflow: hidden;
}
.allsub-wrap .user-wrap .avatar-box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.allsub-wrap .user-wrap .name {
  padding-left: 10px;
  line-height: 38px;
}
.allsub-wrap .am-list-item .am-list-line .am-list-extra {
  padding-top: 14px;
}
.allsub-wrap .nodata-tips {
  line-height: 1.06666667rem;
  text-align: center;
}
.dept-wrap .am-list-item .am-list-line .am-list-content {
  font-size: 0.42666667rem;
}
.dept-wrap .goback {
  background-color: #d8d8d8;
  min-height: 34px;
}
.dept-wrap .goback .am-list-line .am-list-content {
  padding: 0;
  text-align: center;
  font-size: 0.4rem;
}
.dept-wrap .am-list-item.am-list-item-active {
  background-color: #ddd;
}
.dept-wrap .nodata-tips {
  line-height: 1.06666667rem;
  text-align: center;
}
