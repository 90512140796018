body {
  width: 100vw;
  height: 100vh;
  background: #fff !important;
}
body .amap-icon img,
body .amap-marker-content img {
  width: 25px;
  height: 34px;
}
