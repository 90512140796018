@w: 75rem;
.allsub-wrap{
  .am-list-item .am-list-line .am-list-content{
    font-size: 32/@w;
  }
  .goback{
    background-color: #d8d8d8;
    min-height: 34px;
    .am-list-line .am-list-content{
      padding: 0;
      text-align: center;
      font-size: 30/@w;
    }
  }
  .am-list-item.am-list-item-active{
    background-color: #ddd;
  }
  .user-wrap{
    display: flex;
    flex-direction: row;
    .avatar-box{
      width: 38px;
      height: 38px;
      border-radius: 100%;
      overflow: hidden;
      img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      padding-left: 10px;
      line-height: 38px;
    }
  }
  .am-list-item .am-list-line .am-list-extra{
    padding-top: 14px;
  }
  .nodata-tips{
    line-height: 80/@w;
    text-align: center;
  }
}

.dept-wrap{
  .am-list-item .am-list-line .am-list-content{
    font-size: 32/@w;
  }
  .goback{
    background-color: #d8d8d8;
    min-height: 34px;
    .am-list-line .am-list-content{
      padding: 0;
      text-align: center;
      font-size: 30/@w;
    }
  }
  .am-list-item.am-list-item-active{
    background-color: #ddd;
  }
  .nodata-tips{
    line-height: 80/@w;
    text-align: center;
  }
}