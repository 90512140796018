@w: 75rem;
.sign-wrap{
  position: relative;
  background: url('../../assets/img/sign-bg.jpg') #fff9e2 no-repeat center/cover;
  height: 100vh;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  .sign-btn-box{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 250/@w;
    width: 240/@w;
    height: 240/@w;
    .sign-btn{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 240/@w;
      background-image: linear-gradient(#feb100, #ff9500);
      z-index: 100;
      text-align: center;
      .txt{
        padding-top: 80/@w;
        font-size: 44/@w;
        font-weight: bold;
        color: #fff;
      }
      .time{
        font-size: 32/@w;
        color: #fff;
        line-height: 60/@w;
      }
    }
    .sign-btn-active{
      background: #ffba35;
    }
    .sign-btn-success{
      background: #ccc;
    }
    .miner{
      position: absolute;
      width: 198/@w;
      top: -100/@w;
      right: -100/@w;
      z-index: 98;
    }
  }
  .btn-water{
    position: absolute;
    padding: 200/@w;
    left: 50%;
    top: -80/@w;
    z-index: 99;
    opacity: 0;
    border-radius: 100%;
    box-shadow: 0 0 120px 30px rgba(255, 149, 0, 1) inset;
    margin-left: -200/@w;
  }
  .btn-water1 {
    // -webkit-animation: wateranimate 12s 9s ease-out infinite;
    animation: wateranimate 6s 0s ease-out infinite;
  }
  .btn-water2 {
    // -webkit-animation: wateranimate 12s 6s ease-out infinite;
    animation: wateranimate 6s 1s ease-out infinite;
  }			
  .btn-water3 {
    // -webkit-animation: wateranimate 12s 3s ease-out infinite;
    animation: wateranimate 6s 2s ease-out infinite;
  }			
  .btn-water4 {
    // -webkit-animation: wateranimate 12s 0s ease-out infinite;
    animation: wateranimate 6s 3s ease-out infinite;
  }
  .btn-water5 {
    // -webkit-animation: wateranimate 12s 0s ease-out infinite;
    animation: wateranimate 6s 4s ease-out infinite;
  }
  .btn-water6 {
    // -webkit-animation: wateranimate 12s 0s ease-out infinite;
    animation: wateranimate 6s 5s ease-out infinite;
  }

  .success-tips{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .7);
    }
    .tips-panel{
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      background: url('../../assets//img//caidai.png') no-repeat center/cover;
      .tips-time{
        padding:  15% 0;
        font-size: 70/@w;
        color: #fff;
      }
      .tips-body{
        padding-top: 15%;
        box-sizing: border-box;
        // width: 600/@w;
        width: 80%;
        height: 50%;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 4px;
        position: relative;
        text-align: center;
        .tips-gif{
          position: absolute;
          top: -110/@w;
          left: 50%;
          transform: translateX(-50%);
          height: 212/@w;
        }
        .tips-img{
          display: block;
          height: 48%;
          margin: 18/@w auto 0;
        }
        .reward-desc{
          line-height: 78/@w;
          font-size: 35/@w;
          color: #ffa100;
        }
        .slogan{
          padding: 0 56/@w;
          line-height: 36/@w;
          font-size: 26/@w;
          color: #333;
        }
      }
      .tips-fotter{
        padding-top: 70/@w;
        .ok-btn{
          width: 178/@w;
          height: 62/@w;
          line-height: 64/@w;
          border: 1px solid #fff;
          border-radius: 31/@w;
          font-size: 28/@w;
          color: #fff;
          margin: 0 auto;
        }
        .ok-btn-active{
          background: rgba(255, 255, 255, .6);
          color: #282828;
        }
      }
    }
  }


  .signing-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .7);
    }
    .signing-body{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      .img{
        position: absolute;
        width: 70%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .statis-button{
    padding-left: 52/@w;
    position: absolute;
    right: 0;
    top: 30/@w;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    height: 56/@w;
    line-height: 56/@w;
    width: 180/@w;
    border-top-left-radius: 28/@w;
    border-bottom-left-radius: 28/@w;
    .prefix-icon{
      position: absolute;
      top: 14/@w;
      left: 14/@w;
      width: 28/@w;
    }
  }
  .statis-button2{
    top: 96/@w;
  }
}

@-webkit-keyframes wateranimate {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes wateranimate {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}