.hotmap-wrap #hot-container {
  height: 5.6rem;
}
.hotmap-wrap .picker-select {
  position: absolute;
  top: 0.53333333rem;
  left: 20px;
  height: 0.85333333rem;
  line-height: 0.85333333rem;
  border-radius: 0.42666667rem;
  width: 3.46666667rem;
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hotmap-wrap .picker-select .prefix-icon {
  padding-left: 0.37333333rem;
  padding-right: 0.24rem;
}
.hotmap-wrap .picker-select .prefix-icon img {
  display: block;
  width: 0.42666667rem;
}
.hotmap-wrap .err-tips {
  height: 1.33333333rem;
  line-height: 1.33333333rem;
  text-align: center;
  font-size: 0.4rem;
  color: #939393;
}
.hotmap-wrap .content-box {
  overflow-y: auto;
}
.hotmap-wrap .content-box .user-box {
  padding: 0.53333333rem 20px;
  display: flex;
  flex-direction: row;
}
.hotmap-wrap .content-box .user-box .avatar {
  height: 1.06666667rem;
  width: 1.06666667rem;
  border-radius: 100%;
  overflow: hidden;
}
.hotmap-wrap .content-box .user-box .avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hotmap-wrap .content-box .user-box .name {
  line-height: 1.06666667rem;
  font-size: 0.4rem;
  color: #000;
  padding-left: 0.4rem;
}
.hotmap-wrap .content-box .user-box .date {
  padding-left: 0.26666667rem;
  line-height: 1.06666667rem;
}
.hotmap-wrap .content-box .sign-content {
  padding: 0 20px;
}
.hotmap-wrap .content-box .sign-content .sign-item-box {
  margin: 0.48rem 0;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .sign-item-head {
  padding-left: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .sign-item-head .user {
  height: 1.06666667rem;
  line-height: 1.06666667rem;
  padding-right: 14px;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .sign-item-head .date {
  height: 1.06666667rem;
  line-height: 1.06666667rem;
  color: #080808;
  font-size: 0.4rem;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .address {
  position: relative;
  padding: 0.21333333rem 0.8rem 0.4rem;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .address .prefix-icon {
  position: absolute;
  width: 0.32rem;
  top: 0.32rem;
  left: 0.37333333rem;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .address .prefix-icon img {
  display: block;
  width: 1005;
}
.hotmap-wrap .content-box .sign-content .sign-item-box .address .text {
  line-height: 0.58666667rem;
  font-size: 0.4rem;
  color: #7a7a7a;
}
.hotmap-wrap .my-picker-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.hotmap-wrap .my-picker-view .mask {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
.hotmap-wrap .my-picker-view .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
.hotmap-wrap .my-picker-view .content .oper-box {
  line-height: 36px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}
.hotmap-wrap .my-picker-view .content .oper-box .oper-btn {
  color: #108ee9;
}
.hotmap-wrap .my-picker-view .content .title {
  padding: 0 20px;
}
