@w: 75rem;
.timeline-wrap{
  position: relative;
  #hot-container{
    height: 340/@w;
  }
  .picker-select{
    position: absolute;
    top: 110/@w;
    left: 20px;
    height: 64/@w;
    line-height: 64/@w;
    border-radius: 32/@w;
    width: 260/@w;
    background-color: #fff;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: row;
    align-items: center;
    .prefix-icon{
      padding-left: 28/@w;
      padding-right: 18/@w;
      img{
        display: block;
        width: 32/@w;
      }
    }
  }
  .picker-select-end{
    top: 190/@w;
  }
  .am-list-item .am-list-line .am-list-content{
    font-size: 32/@w;
  }
  .am-list-item .am-list-line .am-list-extra{
    font-size: 30/@w;
  }
  .err-tips{
    height: 100/@w;
    line-height: 100/@w;
    text-align: center;
    font-size: 30/@w;
    color: #939393;
  }
  .content-box{
    overflow-y: auto;
    .user-box{
      padding: 40/@w 20px;
      display: flex;
      flex-direction: row;
      .avatar{
        height: 80/@w;
        width: 80/@w;
        border-radius: 100%;
        overflow: hidden;
        img{
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .name{
        line-height: 80/@w;
        font-size: 30/@w;
        color: #000;
        padding-left: 30/@w;
      }
      .date{
        padding-left: 20/@w;
        line-height: 80/@w;
      }
    }
    .sign-content{
      padding: 0 20px;
      .sign-item-box{
        margin-bottom: 36/@w;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        .date{
          padding-left: 28/@w;
          height: 80/@w;
          line-height: 80/@w;
          background-color: #f9f9f9;
          color: #080808;
          font-size: 30/@w;
        }
        .address{
          position: relative;
          padding: 16/@w 60/@w 30/@w;
          .prefix-icon{
            position: absolute;
            width: 24/@w;
            top: 24/@w;
            left: 28/@w;
            img{
              display: block;
              width: 1005;
            }
          }
          .text{
            line-height: 44/@w;
            font-size: 30/@w;
            color: #7a7a7a;
          }
        }
      }
    }
  }

  .my-picker-view{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .2);
      width: 100%;
      height: 100%;
    }
    .content{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      .oper-box{
        line-height: 36px;
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        .oper-btn{
          color: #108ee9;
        }
      }
      .title{
        padding: 0 20px;
      }
    }
  }
}